import apiService from './apiService';

const baseUrl = '/login_check';

const login = (username, password) => new Promise((resolve, reject) => apiService
  .post(
    baseUrl,
    {
      username,
      password,
    },
    {
      isRetryRequest: true,
    },
  )
  .then((response) => {
    window.localStorage.setItem('authToken', response.data.token);
    window.localStorage.setItem('authRefreshToken', response.data.refresh_token);
    apiService.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

    resolve(response);
  })
  .catch((err) => {
    reject(err);
  }));

const logout = () => new Promise((resolve) => {
  window.localStorage.removeItem('authToken');
  window.localStorage.removeItem('authRefreshToken');

  resolve();
});

export default {
  login,
  logout,
};
