import IconButton from '@mui/material/IconButton';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import PropTypes from 'prop-types';

function ShowDetailsButton({ expanded, setExpanded }) {
  return (
    getButton(expanded, setExpanded)
  );
}

const getButton = (expanded, setExpanded) => {
  if (expanded) {
    return getFoldButton(setExpanded);
  }

  return getExpandButton(setExpanded);
};

const getExpandButton = (setExpanded) => (
  <IconButton
    sx={{ color: 'elegant.gray' }}
    aria-label="expand details"
    className="expand-contact"
    onClick={() => setExpanded(true)}
  >
    <UnfoldMoreIcon />
  </IconButton>
);

const getFoldButton = (setExpanded) => (
  <IconButton
    sx={{ color: 'elegant.red' }}
    aria-label="fold details"
    onClick={() => setExpanded(false)}
  >
    <UnfoldLessIcon />
  </IconButton>
);

ShowDetailsButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default ShowDetailsButton;
