import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  ComposableMap,
  Geographies,
  Geography,
} from 'react-simple-maps';
import { geoCylindricalStereographic } from 'd3-geo-projection';
import worldMap from '../../../data/worldMap.json';

// @source https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json
function CountriesMap({ setTooltipContent, setActiveCountryByCode }) {
  return (
    <div>
      <ComposableMap
        projection={geoCylindricalStereographic()
          .translate([window.screen.width / 2, window.screen.height / 2])
          .scale(250)}
        width={window.screen.width}
        height={window.screen.height - 150}
      >
        <Geographies geography={worldMap}>
          {({ geographies }) => geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              onMouseEnter={() => {
                setTooltipContent(geo.properties.NAME);
              }}
              onMouseLeave={() => {
                setTooltipContent('');
              }}
              onClick={() => {
                setActiveCountryByCode(geo.properties.ISO_A2);
              }}
              style={{
                default: {
                  fill: '#202020',
                  outline: 'none',
                },
                hover: {
                  fill: '#625c5d',
                  outline: 'none',
                },
                pressed: {
                  fill: '#e1030a',
                  outline: 'none',
                },
              }}
            />
          ))}
        </Geographies>
      </ComposableMap>
    </div>
  );
}

CountriesMap.propTypes = {
  setTooltipContent: PropTypes.func.isRequired,
  setActiveCountryByCode: PropTypes.func.isRequired,
};

export default memo(CountriesMap);
