import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

function Pagination({
  page, rowsPerPage, setPage, setRowsPerPage, rows, intl,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={rows}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[10, 25, 50, { value: -1, label: intl.formatMessage({ id: 'All' }) }]}
      labelRowsPerPage={intl.formatMessage({ id: 'Contacts per page' })}
      labelDisplayedRows={({ from, to, count }) => `${from} - ${to} ${intl.formatMessage({ id: 'of' })} ${count}`}
    />
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  rows: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
};

const enhance = compose(
  connect((state) => state.authReducer),
  injectIntl,
);

export default enhance(Pagination);
