import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Description({ description }) {
  return (
    <Box
      className="detail-description"
      sx={{
        paddingX: { xs: '5px', sm: '30px' },
        paddingY: '15px',
        textAlign: 'justify',
      }}
    >
      <Typography variant="body2">
        {description}
      </Typography>
    </Box>
  );
}

Description.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Description;
