import { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { ErrorMessage, Field } from 'formik';
import { injectIntl } from 'react-intl';
import {
  FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordStrengthBar from 'react-password-strength-bar';

function Password({
  id, name, label, value, setFieldValue, withStrengthBar, intl,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <FormControl sx={{ width: '100%', marginY: 1 }} variant="outlined">
      <InputLabel htmlFor={id}>
        {label}
      </InputLabel>
      <Field
        component={OutlinedInput}
        variant="outlined"
        id={id}
        name={name}
        label={label}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={(e) => setFieldValue(name, e.target.value)}
        autoComplete={id}
        margin="dense"
        required
        fullWidth
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
      />
      {withStrengthBar && (
        <PasswordStrengthBar
          password={value}
          minLength={8}
          shortScoreWord={intl.formatMessage({ id: 'Too short' })}
          scoreWords={[
            intl.formatMessage({ id: 'Weak' }),
            intl.formatMessage({ id: 'Weak' }),
            intl.formatMessage({ id: 'Okay' }),
            intl.formatMessage({ id: 'Good' }),
            intl.formatMessage({ id: 'Strong' }),
          ]}
        />
      )}
      <ErrorMessage
        component={Typography}
        variant="body2"
        className="text-danger"
        name={name}
        style={{
          width: 'fit-content',
        }}
        sx={{
          color: 'semantic.error',
        }}
      />
    </FormControl>
  );
}

Password.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  withStrengthBar: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

Password.defaultProps = {
  value: '',
  withStrengthBar: false,
};

const enhance = compose(
  injectIntl,
);

export default enhance(Password);
