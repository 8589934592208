import { useState } from 'react';
import * as Yup from 'yup';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  DialogActions, DialogContent, Button, CircularProgress,
} from '@mui/material';
import userService from '../../services/api/userService';
import { ERROR, SUCCESS, notify } from '../../services/notification';
import Password from './Elements/Password';

function ChangePasswordForm({ intl, handleClose }) {
  const [submitting, setSubmitting] = useState(false);

  const submit = (values) => {
    setSubmitting(true);

    userService
      .changePassword(values)
      .then((response) => {
        notify(
          SUCCESS,
          intl.formatMessage({ id: 'Super!' }),
          intl.formatMessage({ id: response.data.success }),
        );

        handleClose();
      })
      .catch((error) => {
        notify(
          ERROR,
          intl.formatMessage({ id: 'Opss...' }),
          intl.formatMessage({ id: error.response.data.errors }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required(intl.formatMessage({ id: 'This field is required' })),
    newPassword: Yup.string()
      .required(intl.formatMessage({ id: 'This field is required' }))
      .min(8, intl.formatMessage({ id: 'Password needs to be at least 8 characters long.' })),
  });

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
      }}
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(formikProps) => (
        <Form>
          <DialogContent>
            <Password
              id="old-password"
              name="oldPassword"
              label={intl.formatMessage({ id: 'Old password' })}
              value={formikProps.values.oldPassword}
              setFieldValue={formikProps.setFieldValue}
            />
            <Password
              id="new-password"
              name="newPassword"
              label={intl.formatMessage({ id: 'New password' })}
              value={formikProps.values.newPassword}
              setFieldValue={formikProps.setFieldValue}
              withStrengthBar
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              type="submit"
              sx={{
                borderColor: '#35af10',
                color: '#35af10',
                ':hover': {
                  color: '#ffffff',
                  borderColor: '#35af10',
                  background: '#35af10',
                },
              }}
              variant="outlined"
              disabled={submitting}
            >
              {submitting ? <CircularProgress sx={{ color: 'semantic.success', mr: '10px' }} size="14px" /> : ''}
              <FormattedMessage id="Change password" />
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

ChangePasswordForm.propTypes = {
  intl: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const enhance = compose(
  injectIntl,
);

export default enhance(ChangePasswordForm);
