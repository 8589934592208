import { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTour } from '@reactour/tour';
import withLayout from '../../higherOrderComponents/withLayout';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import Contact from '../Contact/Contact';
import contactsCollection from '../../data/contacts.json';
import typesCollection from '../../data/types.json';
import Pagination from '../Contact/Pagination';
import FiltersPanel from '../Contact/FiltersPanel';
import NoResults from '../Contact/NoResults';
import MapPanel from '../Contact/MapPanel';

function Contacts({ intl, everSignedIn }) {
  const { setIsOpen } = useTour();

  useEffect(() => {
    const countries = contactsCollection
      .map((contact) => (
        contact.country
      ))
      .sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }

        return a.name > b.name ? 1 : -1;
      })
      .filter((item, pos, ary) => (
        !pos || item.code !== ary[pos - 1].code
      ));

    const types = typesCollection
      .sort((a, b) => {
        if (a.label === b.label) {
          return 0;
        }

        return a.label > b.label ? 1 : -1;
      });

    setCountries(countries);
    setTypes(types);

    if (!everSignedIn) {
      setIsOpen(true);
    }
  }, []);

  const filtersRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countries, setCountries] = useState([]);
  const [activeCountry, setActiveCountry] = useState(null);
  const [types, setTypes] = useState([]);
  const [activeType, setActiveType] = useState(null);
  const [isDirect, setIsDirect] = useState(false);
  const [content, setContent] = useState('');

  const setActiveCountryByCode = (code) => {
    const activeCountryFromMap = countries.find((element) => code.toLowerCase() === element.code);

    if (activeCountryFromMap) {
      setActiveCountry(activeCountryFromMap);
      filtersRef.current.scrollIntoView();
    }

    setContent(intl.formatMessage({ id: 'We do not have contacts in this country' }));
  };

  const getContactsWithPagination = (data) => (
    rowsPerPage > 0
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data
  );

  const getContacts = () => {
    let contacts = contactsCollection;

    if (activeCountry !== null) {
      contacts = contacts.filter((contact) => (
        contact.country.code === activeCountry.code
      ));
    }

    if (activeType !== null) {
      contacts = contacts.filter((contact) => (
        contact.type === activeType.type
      ));
    }

    if (isDirect === true) {
      contacts = contacts.filter((contact) => (
        contact.isDirect === isDirect
      ));
    }

    return sortContacts(contacts);
  };

  const sortContacts = ((contacts) => contacts.sort((a, b) => {
    if (a.country.name === b.country.name) {
      const locationA = a.city || a.island || a.region;
      const locationB = b.city || b.island || b.region;

      if (locationA === locationB) {
        return 0;
      }

      return locationA > locationB ? 1 : -1;
    }

    return a.country.name > b.country.name ? 1 : -1;
  }));

  const resetFilters = () => {
    setActiveCountry(null);
    setActiveType(null);
    setIsDirect(false);
    setPage(0);
  };

  return (
    <>
      <MapPanel
        content={content}
        setContent={setContent}
        setActiveCountryByCode={setActiveCountryByCode}
        filtersRef={filtersRef}
      />
      <FiltersPanel
        countries={countries}
        activeCountry={activeCountry}
        setActiveCountry={setActiveCountry}
        types={types}
        activeType={activeType}
        setActiveType={setActiveType}
        isDirect={isDirect}
        setIsDirect={setIsDirect}
        resetFilters={resetFilters}
        setPage={setPage}
        filtersRef={filtersRef}
      />
      <Box
        component="div"
        className="table-container"
        role="table"
        aria-label={intl.formatMessage({ id: 'Contacts' })}
        sx={{
          display: 'block',
          margin: '2em auto',
        }}
      >
        {getContactsWithPagination(getContacts()).map((contact) => (
          <Contact
            key={contact.id}
            contact={contact}
          />
        ))}
        {getContacts().length === 0 && (
          <NoResults />
        )}
      </Box>
      <Pagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={getContacts().length}
      />
    </>
  );
}

Contacts.propTypes = {
  intl: PropTypes.object.isRequired,
  everSignedIn: PropTypes.bool.isRequired,
};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
  injectIntl,
);

export default enhance(Contacts);
