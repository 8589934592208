import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

function Address({
  city, island, region, country,
}) {
  const address = getLocation(city, island, region, country.name);

  return (
    <Tooltip title={address} placement="top" enterTouchDelay={0}>
      <Chip
        sx={{
          mb: '15px',
        }}
        avatar={(
          <Avatar
            alt={country.name}
            src={`https://flagcdn.com/w80/${country.code}.png`}
          />
        )}
        label={address}
        variant="outlined"
      />
    </Tooltip>
  );
}

const getLocation = (city, island, region, country) => {
  let location = '';

  if (city) {
    location = concatLocation(location, city);
  }

  if (island) {
    location = concatLocation(location, island);
  }

  if (region) {
    location = concatLocation(location, region);
  }

  if (country) {
    location = concatLocation(location, country);
  }

  return location;
};

const concatLocation = (location, part) => {
  if (!location) {
    return part;
  }

  return `${location}, ${part}`;
};

Address.propTypes = {
  city: PropTypes.string,
  island: PropTypes.string,
  region: PropTypes.string,
  country: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
};

Address.defaultProps = {
  city: null,
  island: null,
  region: null,
};

export default Address;
