import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function ContactButton({ href, icon }) {
  return (
    <Link href={href} target="_blank" sx={{ lineHeight: 1, paddingX: '5px' }}>
      <Box component="span" sx={{ color: 'elegant.red' }}>
        {icon}
      </Box>
    </Link>
  );
}

ContactButton.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default ContactButton;
