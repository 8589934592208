import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import ContactButton from '../ContactButton';

function EmailButton({ email }) {
  return (
    <ContactButton href={`mailto:${email}`} icon={<EmailIcon />} />
  );
}

EmailButton.propTypes = {
  email: PropTypes.string.isRequired,
};

export default EmailButton;
