import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import Navigation from './Navigation';
import ChangePasswordModal from './Account/ChangePasswordModal';

function Layout({ children }) {
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  return (
    <>
      <CssBaseline />
      <Navigation setChangePasswordModalOpen={setChangePasswordModalOpen} />
      <ChangePasswordModal open={changePasswordModalOpen} setOpen={setChangePasswordModalOpen} />
      <main style={{ background: '#f3f3f3', height: '100%' }}>
        <Container
          maxWidth="lg"
          sx={{ p: '30px' }}
        >
          {children}
        </Container>
      </main>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;
