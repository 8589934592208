import { Link as RouteLink, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import withSessionRedirect from '../../higherOrderComponents/withSessionRedirect';
import withAnonymousLayout from '../../higherOrderComponents/withAnonymousLayout';
import Copyright from '../Layout/Copyright';
import ResetPasswordForm from '../Forms/ResetPasswordForm';

function PasswordReset() {
  const { token } = useParams();

  return (
    <>
      <Typography component="h1" variant="h5">
        <FormattedMessage id="Enter new password" />
      </Typography>
      <Box component="div" sx={{ mt: 1, width: '100%' }}>
        <ResetPasswordForm token={token} />
        <Grid container>
          <Grid item xs>
            <Link component={RouteLink} to="/" variant="body2">
              <FormattedMessage id="If you remember password, sign in" />
            </Link>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </>
  );
}

const enhance = compose(
  connect((state) => state.authReducer),
  withSessionRedirect,
  withAnonymousLayout,
);

export default enhance(PasswordReset);
