import api from './apiService';
import openApi from './openApiService';

const changePasswordUrl = '/change-password';
const resetPasswordRequestUrl = '/reset-password/request?XDEBUG_SESSION_START=PHPSTORM';
const resetPasswordUrl = '/reset-password/reset';

const userService = {
  changePassword,
  resetPasswordRequest,
  resetPassword,
};

function changePassword(params) {
  return api.patch(changePasswordUrl, params);
}

function resetPasswordRequest(params) {
  return openApi.post(resetPasswordRequestUrl, params);
}

function resetPassword(token, params) {
  return openApi.patch(
    `${resetPasswordUrl}/${token}?XDEBUG_SESSION_START=PHPSTORM`,
    params,
  );
}

export default userService;
