import PropTypes from 'prop-types';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

function DirectContact({ isDirect }) {
  return isDirect ? (
    <Tooltip title="Bez pośredników" enterTouchDelay={0}>
      <Avatar sx={{
        backgroundColor: '#fff',
        color: 'semantic.success',
        width: '28px',
        height: '28px',
      }}
      >
        <LoyaltyIcon fontSize="medium" />
      </Avatar>
    </Tooltip>
  ) : <div />;
}

DirectContact.propTypes = {
  isDirect: PropTypes.bool.isRequired,
};

export default DirectContact;
