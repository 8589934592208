import PropTypes from 'prop-types';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ContactButton from '../ContactButton';
import { sanitizePhoneNumber } from '../../../../services/sanitizer';

function PhoneButton({ phone }) {
  return (
    <ContactButton href={`tel:${sanitizePhoneNumber(phone)}`} icon={<PhoneIphoneIcon />} />
  );
}

PhoneButton.propTypes = {
  phone: PropTypes.string.isRequired,
};

export default PhoneButton;
