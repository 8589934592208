import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { logger } from 'redux-logger';
import authReducer from '../reducers/authReducer';

const reducers = {
  authReducer,
};

const middlewares = [ReduxThunk, logger];
const reducer = combineReducers(reducers);
const store = createStore(reducer, {}, applyMiddleware(...middlewares));

export default store;
