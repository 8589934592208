import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Description from './Details/Block/Description';
import Location from './Details/Block/Location';
import Links from './Details/Block/Links';

function ContactDetails({ contact }) {
  return (
    <Box
      component="div"
      className="details-grid"
      sx={{
        p: '15px',
        mb: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Location
        country={contact.country}
        region={contact.region}
        island={contact.island}
        city={contact.city}
        coords={contact.coords}
      />
      <Description description={contact.description} />
      <Links
        phone={contact.phone}
        whatsapp={contact.whatsapp}
        email={contact.email}
        messenger={contact.messenger}
        link={contact.link}
        linkText={contact.linkText}
      />
    </Box>
  );
}

ContactDetails.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    whatsapp: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    messenger: PropTypes.string.isRequired,
    country: PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    city: PropTypes.string.isRequired,
    island: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    coords: PropTypes.shape({
      lon: PropTypes.string.isRequired,
      lat: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactDetails;
