import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';

function NoResults() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingY: '30px',
        color: 'elegant.gray',
      }}
    >
      <TravelExploreIcon sx={{ fontSize: '50px', mb: '20px', color: 'elegant.gray' }} />
      <FormattedMessage id="There is no results for chosen filters" />
    </Box>
  );
}

export default NoResults;
