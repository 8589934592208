import { ErrorMessage, Field } from 'formik';
import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function Email({
  id, name, label, value, setFieldValue, autoFocus,
}) {
  return (
    <>
      <Field
        component={TextField}
        variant="outlined"
        id={id}
        name="username"
        label={label}
        value={value}
        onChange={(e) => setFieldValue(name, e.target.value)}
        autoComplete={id}
        margin="dense"
        required
        fullWidth
        autoFocus={autoFocus}
      />
      <ErrorMessage
        component={Typography}
        variant="body2"
        className="text-danger"
        name="username"
        style={{
          width: 'fit-content',
        }}
        sx={{
          color: 'semantic.error',
        }}
      />
    </>
  );
}

Email.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

Email.defaultProps = {
  value: '',
  autoFocus: false,
};

export default Email;
