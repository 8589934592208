import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import pinImage from '../assets/images/pin.png';
import backgroundImage from '../assets/images/sign-in-background.jpg';

function AnonymousLayout({ children }) {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              maxWidth: 50,
              mb: 2,
            }}
            src={pinImage}
            alt={<FormattedMessage id="Takeyourway logo" />}
          />
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}

AnonymousLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AnonymousLayout;
