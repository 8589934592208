import {
  Dialog,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import ChangePasswordForm from '../Forms/ChangePasswordForm';

function ChangePasswordModal({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <FormattedMessage id="Change password" />
      </DialogTitle>
      <ChangePasswordForm handleClose={handleClose} />
    </Dialog>
  );
}

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const enhance = compose(
  injectIntl,
);

export default enhance(ChangePasswordModal);
