import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import withRestrictedAccess from '../../higherOrderComponents/withRestrictedAccess';
import withLayout from '../../higherOrderComponents/withLayout';

function Profile({ username, expirationDate }) {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    setUserData({
      email: username,
      expirationDate,
    });
  }, []);

  return (
    <Paper elevation={1}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 5,
        }}
      >
        <Typography
          component="h2"
          variant="h5"
          sx={{
            mb: 4,
            fontSize: { xs: '1rem', sm: '1.5rem' },
          }}
        >
          {username}
        </Typography>
        <Box>
          <Alert severity="success" icon={<AccessTimeIcon />}>
            <FormattedMessage id="Your account is active to" />
            {' '}
            <Box component="span" sx={{ fontWeight: '600' }}>
              {moment(userData.expirationDate?.date).format('DD.MM.YYYY')}
            </Box>
          </Alert>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mt: 4,
          }}
        >
          <Typography component="p" variant="subtitle2" sx={{ mb: '4px' }}>
            <FormattedMessage id="There will be an option to renew your account here in the future." />
          </Typography>
          <Typography component="p" variant="body2">
            <FormattedMessage id="Don not worry, you still have plenty of time." />
          </Typography>
        </Box>
        <Button
          component={Link}
          to="/"
          variant="contained"
          startIcon={<ArrowBackIcon />}
          sx={{
            mt: 5,
            fontSize: { xs: '12px', sm: '14px' },
          }}
        >
          <FormattedMessage id="Back to contact list" />
        </Button>
      </Box>
    </Paper>
  );
}

Profile.propTypes = {
  username: PropTypes.string.isRequired,
  expirationDate: PropTypes.object.isRequired,
};

const enhance = compose(
  connect((state) => state.authReducer),
  withRestrictedAccess,
  withLayout,
);

export default enhance(Profile);
