import * as React from 'react';
import AnonymousLayout from '../components/AnonymousLayout';

function withAnonymousLayout(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <AnonymousLayout>
          <WrappedComponent {...this.props} />
        </AnonymousLayout>
      );
    }
  };
}

export default withAnonymousLayout;
