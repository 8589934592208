import { Link as RouteLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import SignInForm from '../Forms/SignInForm';
import withSessionRedirect from '../../higherOrderComponents/withSessionRedirect';
import withAnonymousLayout from '../../higherOrderComponents/withAnonymousLayout';
import Copyright from '../Layout/Copyright';

function SignIn() {
  return (
    <>
      <Typography component="h1" variant="h5">
        <FormattedMessage id="Application sign in" />
      </Typography>
      <Box component="div" sx={{ mt: 1 }}>
        <SignInForm />
        <Grid container>
          <Grid item xs>
            <Link component={RouteLink} to="/resetuj-haslo" variant="body2">
              <FormattedMessage id="Lost password?" />
            </Link>
          </Grid>
          <Grid item>
            <Link component={RouteLink} to="/rejestracja" variant="body2">
              <FormattedMessage id="Do not have an account yet? Get access!" />
            </Link>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </>
  );
}

const enhance = compose(
  connect((state) => state.authReducer),
  withSessionRedirect,
  withAnonymousLayout,
);

export default enhance(SignIn);
