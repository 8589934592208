import * as React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import authService from '../../services/api/authService';
import { login } from '../../reducers/authActions';
import { ERROR, notify } from '../../services/notification';
import Password from './Elements/Password';
import Submit from './Elements/Submit';
import Email from './Elements/Email';

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
  }

  submit = (values) => {
    this.setState({ submitting: true });

    authService
      .login(values.username, values.password)
      .then((response) => {
        const { dispatch } = this.props;

        dispatch(login(response.data.token));
      })
      .catch(() => {
        notify(
          ERROR,
          this.props.intl.formatMessage({ id: 'Opss...' }),
          this.props.intl.formatMessage({ id: 'Sign in failed.' }),
        );
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  render() {
    const validationSchema = Yup.object().shape({
      username: Yup.string().required(
        this.props.intl.formatMessage({ id: 'This field is required' }),
      ),
      password: Yup.string().required(
        this.props.intl.formatMessage({ id: 'This field is required' }),
      ),
    });
    return (
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={this.submit}
      >
        {(props) => (
          <Form>
            <Email
              id="login"
              name="username"
              label={this.props.intl.formatMessage({ id: 'Email' })}
              value={props.values.username}
              setFieldValue={props.setFieldValue}
              autoFocus
            />
            <Password
              id="password"
              name="password"
              label={this.props.intl.formatMessage({ id: 'Password' })}
              value={props.values.password}
              setFieldValue={props.setFieldValue}
            />
            <Submit
              submitting={this.state.submitting}
              label={this.props.intl.formatMessage({ id: 'Sign in' })}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

SignInForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

const enhance = compose(
  connect((state) => state.authReducer),
  injectIntl,
);

export default enhance(SignInForm);
