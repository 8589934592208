import PropTypes from 'prop-types';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactButton from '../ContactButton';
import { sanitizeWhatsAppNumber } from '../../../../services/sanitizer';

function WhatsAppButton({ whatsapp }) {
  return (
    <ContactButton href={`https://wa.me/${sanitizeWhatsAppNumber(whatsapp)}`} icon={<WhatsAppIcon />} />
  );
}

WhatsAppButton.propTypes = {
  whatsapp: PropTypes.string.isRequired,
};

export default WhatsAppButton;
