import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';

function Submit({ submitting, label }) {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
    >
      {submitting ? <CircularProgress sx={{ color: 'semantic.success', mr: '10px' }} size="14px" /> : ''}
      {label}
    </Button>
  );
}

Submit.propTypes = {
  submitting: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

Submit.defaultProps = {
  submitting: false,
};

export default Submit;
