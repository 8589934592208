import PropTypes from 'prop-types';
import { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Name from './Row/Name';
import ShowDetailsButton from './Row/ShowDetailsButton';
import Type from './Row/Type';
import DirectContact from './Row/DirectContact';
import Location from './Row/Location';
import ContactDetails from './ContactDetails';

function Contact({ contact, intl }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        background: '#fff',
        borderRadius: '5px',
        boxShadow: 1,
        mb: '10px',
      }}
    >
      <Box
        component="div"
        className="flex-table row"
        role="rowgroup"
        sx={{
          display: 'grid',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          component="div"
          className="flex-column first column-flag"
          role="cell"
          sx={{
            display: 'flex',
            overflow: 'hidden',
            height: '56px',
            width: '84px',
            backgroundImage: `url(https://flagcdn.com/h160/${contact.country.code}.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionY: 'center',
            borderRadius: '5px 0 0 5px',
          }}
        >
          <Tooltip title={contact.country.name} enterTouchDelay={0}>
            <div style={{ height: '100%', width: '100%' }} />
          </Tooltip>
        </Box>
        <Box
          component="div"
          className="flex-column column-name"
          role="cell"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '40px',
          }}
        >
          <Type
            type={contact.type}
            icon={contact.icon}
            tooltip={contact.tooltip}
            isRecommended={contact.isRecommended}
          />
          <Name name={contact.name} />
        </Box>
        <div className="flex-column column-location" role="cell">
          <Location city={contact.city} island={contact.island} region={contact.region} />
        </div>
        <Box
          className="flex-column column-action"
          role="cell"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <DirectContact isDirect={contact.isDirect} />
          </Box>
          <ShowDetailsButton expanded={expanded} setExpanded={setExpanded} />
        </Box>
      </Box>
      <Collapse in={expanded}>
        <Divider sx={{ width: '70%', m: '0 auto', pt: '15px' }} light>
          <Typography variant="subtitle2">
            {intl.formatMessage({ id: 'Contact details' })}
          </Typography>
        </Divider>
        <ContactDetails contact={contact} />
      </Collapse>
    </Box>
  );
}

Contact.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    whatsapp: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    messenger: PropTypes.string.isRequired,
    country: PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    city: PropTypes.string.isRequired,
    island: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    coords: PropTypes.shape({
      lon: PropTypes.string.isRequired,
      lat: PropTypes.string.isRequired,
    }),
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isDirect: PropTypes.bool.isRequired,
    isRecommended: PropTypes.bool.isRequired,
  }).isRequired,
  intl: PropTypes.object.isRequired,
};

const enhance = compose(
  connect((state) => state.authReducer),
  injectIntl,
);

export default enhance(Contact);
