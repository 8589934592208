import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import ButterToast, { POS_TOP, POS_RIGHT } from 'butter-toast';
import { TourProvider } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './services/store';
import messages from './translations/pl.json';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

const mapEnabledBreakpoint = 900;
const globalTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        body2: ({ theme }) => ({
          color: theme.palette.elegant.gray,
          fontSize: '14px',
        }),
        hyperlink: ({ theme }) => ({
          color: theme.palette.elegant.red,
          fontSize: '14px',
        }),
      },
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '-apple-system',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#202020',
      contrastText: '#EBEBD3',
    },
    secondary: {
      main: '#e1030a',
      contrastText: '#EBEBD3',
    },
    semantic: {
      success: '#058f12',
      info: '#108d9d',
      warning: '#e0b300',
      error: '#ef0029',
    },
    elegant: {
      white: '#fafafa',
      lightGray: '#e7e1e1',
      gray: '#625c5d',
      red: '#e1030a',
      black: '#202020',
    },
  },
});

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);
const getTourSteps = () => {
  let tourSteps = [];

  if (window.screen.width >= mapEnabledBreakpoint) {
    tourSteps = [
      {
        selector: '.map-container',
        content: 'Z mapy świata możesz wybrać kraj lub...',
      },
      {
        selector: '.go-to-contacts-list',
        content: '...przejść od razu do listy kontaktów i tam skorzystać z filtrów.',
      },
    ];
  }

  return [
    ...tourSteps,
    {
      selector: '.filters',
      content: 'Za pomocą filtrów możesz ograniczyć widoczne kontakty do konkretnego kraju oraz rodzaju (np. nocleg lub wynajem pojazdu).',
    },
    {
      selector: '.expand-contact:first-of-type',
      content: 'Klikając w ten przycisk przy interesującym Cię kontakcie, możesz zobaczyć jego szczegóły.',
    },
  ];
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={globalTheme}>
        <IntlProvider locale="pl" messages={messages}>
          <TourProvider
            steps={getTourSteps()}
            afterOpen={disableBody}
            beforeClose={enableBody}
          >
            <App />
          </TourProvider>
        </IntlProvider>
      </ThemeProvider>
      <ButterToast position={{
        vertical: POS_TOP,
        horizontal: POS_RIGHT,
      }}
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
