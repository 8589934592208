import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contacts from './components/Pages/Contacts';
import SignIn from './components/Pages/SignIn';
import Profile from './components/Pages/Profile';
import PasswordReset from './components/Pages/PasswordReset';
import RequestPasswordReset from './components/Pages/RequestPasswordReset';

import './App.scss';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/logowanie" element={<SignIn />} />
        <Route path="/resetuj-haslo" element={<RequestPasswordReset />} />
        <Route path="/reset/:token" element={<PasswordReset />} />
        <Route path="/profil" element={<Profile />} />
        <Route path="/" element={<Contacts />} />
      </Routes>
    </Router>
  );
}

export default App;
