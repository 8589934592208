import axios from 'axios';

const successCallback = (response) => response;
const errorCallback = (error) => Promise.reject(error);

const openApiService = axios.create({ baseURL: process.env.REACT_APP_OPEN_API });
openApiService.defaults.headers.common['Accept-Language'] = 'pl';
openApiService.interceptors.response.use(successCallback, errorCallback);

export default openApiService;
