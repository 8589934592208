import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function withSessionRedirect(WrappedComponent) {
  function Wrapper(props) {
    const { authenticated } = props;

    return (
      <div>
        {authenticated && <Navigate to={{ pathname: '/' }} />}
        {!authenticated && <WrappedComponent {...props} />}
      </div>
    );
  }

  Wrapper.propTypes = {
    authenticated: PropTypes.bool.isRequired,
  };

  return Wrapper;
}
