import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function withRestrictedAccess(WrappedComponent) {
  function Wrapper(props) {
    const { authenticated } = props;

    const error = '';
    return (
      <div>
        {(authenticated && <WrappedComponent {...props} />)}
        {(!authenticated) && (<Navigate to={{ pathname: '/logowanie', state: { error } }} />)}
      </div>
    );
  }

  Wrapper.propTypes = {
    authenticated: PropTypes.bool.isRequired,
  };

  return Wrapper;
}
