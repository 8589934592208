import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import BungalowIcon from '@mui/icons-material/Bungalow';
import CarRentalIcon from '@mui/icons-material/CarRental';
import CelebrationIcon from '@mui/icons-material/Celebration';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import GavelIcon from '@mui/icons-material/Gavel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import PetsIcon from '@mui/icons-material/Pets';
import SurfingIcon from '@mui/icons-material/Surfing';
import StarIcon from '@mui/icons-material/Star';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const ICON_ACTIVITY = 'activity';
const ICON_ANIMALS = 'animals';
const ICON_APARTMENT = 'apartment';
const ICON_BOAT = 'boat';
const ICON_BUNGALOW = 'bungalow';
const ICON_CAMPING = 'camping';
const ICON_CAR = 'car';
const ICON_HOSPITAL = 'hospital';
const ICON_LAWYER = 'lawyer';
const ICON_SCOOTER = 'scooter';
const ICON_SURFING = 'surfing';
const ICON_TAXI = 'taxi';
const ICON_VEHICLE = 'vehicle';
const ICON_WALK = 'walk';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  backgroundColor: theme.palette.semantic.warning,
  border: `2px solid ${theme.palette.elegant.black}`,
}));

function Type({
  icon, tooltip, isRecommended, intl,
}) {
  return (
    isRecommended ? getAvatarWithBadge(icon, tooltip, intl) : getAvatarWithTooltip(icon, tooltip)
  );
}

const getAvatarWithBadge = (icon, tooltip, intl) => (
  <Badge
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={(
      <Tooltip title={intl.formatMessage({ id: 'Super!' })} enterTouchDelay={0}>
        <SmallAvatar alt={intl.formatMessage({ id: 'Super!' })}>
          <StarIcon sx={{ width: '10px', height: '10px', color: '#fff' }} />
        </SmallAvatar>
      </Tooltip>
    )}
  >
    {getAvatarWithTooltip(icon, tooltip)}
  </Badge>
);

const getAvatarWithTooltip = (icon, tooltip) => (
  <Tooltip title={tooltip} enterTouchDelay={0}>
    {getAvatar(icon)}
  </Tooltip>
);

const getAvatar = (icon) => (
  <Avatar sx={{ backgroundColor: 'elegant.black', width: '36px', height: '36px' }}>
    {getIcon(icon)}
  </Avatar>
);

const getIcon = (icon) => {
  switch (icon) {
    case ICON_ACTIVITY:
      return <CelebrationIcon />;
    case ICON_ANIMALS:
      return <PetsIcon />;
    case ICON_APARTMENT:
      return <HomeWorkIcon />;
    case ICON_BOAT:
      return <DirectionsBoatIcon />;
    case ICON_BUNGALOW:
      return <BungalowIcon />;
    case ICON_CAMPING:
      return <BedtimeIcon />;
    case ICON_CAR:
      return <CarRentalIcon />;
    case ICON_HOSPITAL:
      return <LocalHospitalIcon />;
    case ICON_LAWYER:
      return <GavelIcon />;
    case ICON_SCOOTER:
      return <TwoWheelerIcon />;
    case ICON_SURFING:
      return <SurfingIcon />;
    case ICON_TAXI:
      return <LocalTaxiIcon />;
    case ICON_VEHICLE:
      return <BikeScooterIcon />;
    case ICON_WALK:
      return <DirectionsWalkIcon />;
    default:
      return <HelpOutlineIcon />;
  }
};

Type.propTypes = {
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  isRecommended: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

const enhance = compose(
  injectIntl,
);

export default enhance(Type);
