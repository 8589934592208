import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import { Button, Tooltip, Typography } from '@mui/material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CountriesMap from './Map/CountriesMap';

function MapPanel({
  content, setContent, setActiveCountryByCode, filtersRef,
}) {
  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          component="span"
          variant="h4"
        >
          <FormattedMessage id="Choose country from map" />
        </Typography>
        <Button
          variant="contained"
          className="go-to-contacts-list"
          endIcon={<ArrowDropDownCircleIcon />}
          sx={{ mt: 2, mb: 6 }}
          onClick={() => filtersRef.current.scrollIntoView()}
        >
          <FormattedMessage id="or go to the contacts list" />
        </Button>
      </Box>
      <Tooltip title={content} followCursor enterTouchDelay={0} open>
        <Box className="map-container">
          <CountriesMap
            setTooltipContent={setContent}
            setActiveCountryByCode={setActiveCountryByCode}
          />
        </Box>
      </Tooltip>
    </Box>
  );
}

MapPanel.propTypes = {
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  setActiveCountryByCode: PropTypes.func.isRequired,
  filtersRef: PropTypes.object.isRequired,
};

export default MapPanel;
