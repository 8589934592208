import PropTypes from 'prop-types';
import FacebookIcon from '@mui/icons-material/Facebook';
import ContactButton from '../ContactButton';

function MessengerButton({ messenger }) {
  return (
    <ContactButton href={`https://m.me/${messenger}`} icon={<FacebookIcon />} />
  );
}

MessengerButton.propTypes = {
  messenger: PropTypes.string.isRequired,
};

export default MessengerButton;
