import ButterToast, { Cinnamon } from 'butter-toast';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InfoIcon from '@mui/icons-material/Info';

export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const INFO = 'INFO';

const iconResolver = (type) => {
  switch (type) {
    case SUCCESS:
      return <CheckIcon />;
    case ERROR:
      return <PriorityHighIcon />;
    default:
      return <InfoIcon />;
  }
};

const schemeResolver = (type) => {
  switch (type) {
    case SUCCESS:
      return Cinnamon.Crunch.SCHEME_GREEN;
    case ERROR:
      return Cinnamon.Crunch.SCHEME_RED;
    default:
      return Cinnamon.Crunch.SCHEME_BLUE;
  }
};

export const notify = (type, title, content) => {
  ButterToast.raise({
    content: <Cinnamon.Crunch
      icon={iconResolver(type)}
      title={title}
      content={content}
      scheme={schemeResolver(type)}
    />,
    timeout: 4000,
  });
};
