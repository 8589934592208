import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function Name({ name }) {
  return (
    <Typography
      variant="h2"
      color="elegant.black"
      sx={{
        fontSize: { xs: 14, sm: 16 },
        paddingLeft: { xs: '20px', sm: '30px' },
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {name}
    </Typography>
  );
}

Name.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Name;
