import parseJwt from '../services/jwtParser';

const token = window.localStorage.getItem('authToken');
let username = 'anon';
let expirationDate = null;
let everSignedIn = false;

if (token) {
  const authToken = parseJwt(token);

  username = authToken.username;
  expirationDate = authToken.expirationDate;
  everSignedIn = authToken.everSignedIn;
}

const initialState = {
  authenticated: Boolean(token),
  username,
  expirationDate,
  everSignedIn,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        authenticated: true,
        username: action.payload.username,
        expirationDate: action.payload.expirationDate,
        everSignedIn: action.payload.everSignedIn,
      };
    case 'LOGGED_OUT':
      return {
        authenticated: false,
        username: 'anon',
        expirationDate: null,
        everSignedIn: null,
      };
    default:
      return state;
  }
};
