import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MapIcon from '@mui/icons-material/Map';
import Address from '../Address';
import MapButton from '../MapButton';

function Location({
  country, region, island, city, coords,
}) {
  return (
    <Box
      className="detail-address"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MapIcon
        className="map-icon"
        sx={{
          color: 'elegant.lightGray',
          fontSize: '100px',
          mb: '20px',
        }}
      />
      <Address
        city={city}
        island={island}
        region={region}
        country={country}
      />
      <MapButton coords={coords} />
    </Box>
  );
}

Location.propTypes = {
  city: PropTypes.string,
  island: PropTypes.string,
  region: PropTypes.string,
  country: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  coords: PropTypes.shape({
    lon: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
  }).isRequired,
};

Location.defaultProps = {
  city: null,
  island: null,
  region: null,
};

export default Location;
