import PropTypes from 'prop-types';
import CarRentalIcon from '@mui/icons-material/CarRental';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import HomeIcon from '@mui/icons-material/Home';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { Box } from '@mui/material';

const TYPE_ACCOMMODATION = 'ACCOMMODATION';
const TYPE_ACTIVITY = 'ACTIVITY';
const TYPE_DOCTOR = 'DOCTOR';
const TYPE_DRIVER = 'DRIVER';
const TYPE_VEHICLE = 'VEHICLE';

function TypeFilterOption({ option }) {
  return (
    <>
      {getIcon(option.type)}
      <Box
        component="span"
        sx={{
          ml: '7px',
        }}
      >
        {option.label}
      </Box>
    </>
  );
}

const getIcon = (type) => {
  switch (type) {
    case TYPE_ACCOMMODATION:
      return <HomeIcon />;
    case TYPE_ACTIVITY:
      return <InsertEmoticonIcon />;
    case TYPE_DOCTOR:
      return <LocalHospitalIcon />;
    case TYPE_DRIVER:
      return <LocalTaxiIcon />;
    case TYPE_VEHICLE:
      return <CarRentalIcon />;
    default:
      return '';
  }
};

TypeFilterOption.propTypes = {
  option: PropTypes.object.isRequired,
};

export default TypeFilterOption;
