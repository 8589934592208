import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FilterListIcon from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { compose } from 'redux';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TypeFilterOption from './Filters/TypeFilterOption';

function FiltersPanel({
  countries, activeCountry, setActiveCountry, isDirect, setIsDirect, types, activeType,
  setActiveType, resetFilters, setPage, filtersRef,
}) {
  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState(true);

  return (
    <Box ref={filtersRef}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isFiltersPanelOpen && (
          <Button
            size="small"
            color="secondary"
            onClick={() => {
              resetFilters();
            }}
          >
            <FormattedMessage id="Clear" />
          </Button>
        )}
        <IconButton
          sx={{ color: `${isFiltersPanelOpen ? 'elegant.red' : 'elegant.gray'}` }}
          aria-label="toggle filters panel"
          onClick={() => {
            setIsFiltersPanelOpen(!isFiltersPanelOpen);
          }}
        >
          <FilterListIcon />
        </IconButton>
      </Box>
      {isFiltersPanelOpen && (
        <Grid
          sx={{ paddingBottom: '30px' }}
          container
          className="filters"
          justify="center"
          spacing={3}
        >
          <Grid item md={4} sm={6} xs={12}>
            <FormControl style={{ width: '100%' }}>
              <Autocomplete
                id="country-select-demo"
                options={countries}
                autoHighlight
                autoComplete
                getOptionLabel={(option) => option.name}
                noOptionsText={<FormattedMessage id="We do not have contacts in this country" />}
                value={activeCountry}
                onChange={(e, value) => {
                  setActiveCountry(value);
                  setPage(0);
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      style={{ marginRight: '5px' }}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt={option.name}
                    />
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="Choose country" />}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <FormControl style={{ width: '100%' }}>
              <Autocomplete
                id="type-select-demo"
                options={types}
                autoHighlight
                autoComplete
                getOptionLabel={(option) => option.label}
                noOptionsText={<FormattedMessage id="Search for something else" />}
                value={activeType}
                onChange={(e, value) => {
                  setActiveType(value);
                  setPage(0);
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <TypeFilterOption option={option} />
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="Type what you are looking for" />}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={(
                <Switch
                  checked={isDirect}
                  onChange={(e, checked) => { setIsDirect(checked); setPage(0); }}
                />
              )}
              label={(
                <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                  <FormattedMessage id="Direct contacts only" />
                  <LoyaltyIcon sx={{ fontSize: '26px', pl: '5px', color: 'semantic.success' }} />
                </Box>
              )}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

FiltersPanel.propTypes = {
  countries: PropTypes.array.isRequired,
  activeCountry: PropTypes.object,
  setActiveCountry: PropTypes.func.isRequired,
  isDirect: PropTypes.bool.isRequired,
  setIsDirect: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  activeType: PropTypes.object,
  setActiveType: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  filtersRef: PropTypes.object.isRequired,
};

FiltersPanel.defaultProps = {
  activeCountry: null,
  activeType: null,
};

const enhance = compose(
  injectIntl,
);

export default enhance(FiltersPanel);
