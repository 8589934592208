import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function WebsiteButton({ link, linkText }) {
  return (
    <Typography variant="hyperlink">
      <Link
        href={link}
        target="_blank"
        rel="noopener"
        underline="hover"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'elegant.red',
        }}
      >
        {linkText}
        <OpenInNewIcon sx={{ fontSize: '14px', ml: '3px' }} />
      </Link>
    </Typography>
  );
}

WebsiteButton.propTypes = {
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default WebsiteButton;
