import PropTypes from 'prop-types';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import Typography from '@mui/material/Typography';

function Location({ city, island, region }) {
  return (
    <Typography
      variant="body2"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <ShareLocationIcon fontSize="large" sx={{ color: 'elegant.lightGray' }} />
      <Typography
        variant="div"
        sx={{
          display: 'block',
          fontSize: 14,
          paddingLeft: '10px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        color="elegant.gray"
      >
        {getLocation(city, island, region)}
      </Typography>
    </Typography>
  );
}

const getLocation = (city, island, region) => {
  if (city && island) {
    return `${city}, ${island}`;
  }

  if (!city && !island) {
    return region;
  }

  return city || island;
};

Location.propTypes = {
  city: PropTypes.string,
  island: PropTypes.string,
  region: PropTypes.string,
};

Location.defaultProps = {
  city: null,
  island: null,
  region: null,
};

export default Location;
