import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import WebsiteButton from '../WebsiteButton';
import PhoneButton from '../ContactButtons/PhoneButton';
import WhatsAppButton from '../ContactButtons/WhatsAppButton';
import EmailButton from '../ContactButtons/EmailButton';
import MessengerButton from '../ContactButtons/MessengerButton';

function Links({
  phone, whatsapp, email, messenger, link, linkText,
}) {
  return (
    <Box
      component="div"
      className="detail-contact"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {phone && (
          <PhoneButton phone={phone} />
        )}
        {whatsapp && (
          <WhatsAppButton whatsapp={whatsapp} />
        )}
        {email && (
          <EmailButton email={email} />
        )}
        {messenger && (
          <MessengerButton messenger={messenger} />
        )}
      </Box>
      {link && (
        <Box sx={{ mt: '15px' }}>
          <WebsiteButton link={link} linkText={linkText} />
        </Box>
      )}
    </Box>
  );
}

Links.propTypes = {
  phone: PropTypes.string.isRequired,
  whatsapp: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  messenger: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default Links;
