import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ERROR, SUCCESS, notify } from '../../services/notification';
import userService from '../../services/api/userService';
import Submit from './Elements/Submit';
import Email from './Elements/Email';

function RequestResetPasswordForm({ intl }) {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const submit = (values) => {
    setSubmitting(true);

    userService
      .resetPasswordRequest(values)
      .then((response) => {
        notify(
          SUCCESS,
          intl.formatMessage({ id: 'Super!' }),
          intl.formatMessage({ id: response.data.success }),
        );

        navigate('/');
      })
      .catch((error) => {
        notify(
          ERROR,
          intl.formatMessage({ id: 'Opss...' }),
          intl.formatMessage({ id: error.response.data.errors }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required(intl.formatMessage({ id: 'This field is required' }))
      .email(intl.formatMessage({ id: 'Email address is incorrect.' })),
  });

  return (
    <Formik
      initialValues={{
        username: '',
      }}
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(formikProps) => (
        <Form>
          <Email
            id="login"
            name="username"
            label={intl.formatMessage({ id: 'Email' })}
            value={formikProps.values.username}
            setFieldValue={formikProps.setFieldValue}
            autoFocus
          />
          <Submit
            submitting={submitting}
            label={intl.formatMessage({ id: 'Reset password' })}
          />
        </Form>
      )}
    </Formik>
  );
}

RequestResetPasswordForm.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

const enhance = compose(
  injectIntl,
);

export default enhance(RequestResetPasswordForm);
