import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ERROR, SUCCESS, notify } from '../../services/notification';
import userService from '../../services/api/userService';
import Password from './Elements/Password';
import Submit from './Elements/Submit';

function ResetPasswordForm({ token, intl }) {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const submit = (values) => {
    setSubmitting(true);

    userService
      .resetPassword(token, values)
      .then(() => {
        notify(
          SUCCESS,
          intl.formatMessage({ id: 'Super!' }),
          intl.formatMessage({ id: 'The password has been changed.' }),
        );

        navigate('/');
      })
      .catch(() => {
        notify(
          ERROR,
          intl.formatMessage({ id: 'Opss...' }),
          intl.formatMessage({ id: 'Something went wrong.' }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(intl.formatMessage({ id: 'This field is required' }))
      .min(8, intl.formatMessage({ id: 'Password needs to be at least 8 characters long.' })),
  });

  return (
    <Formik
      initialValues={{
        newPassword: '',
      }}
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(formikProps) => (
        <Form>
          <Password
            id="new-password"
            name="newPassword"
            label={intl.formatMessage({ id: 'New password' })}
            value={formikProps.values.newPassword}
            setFieldValue={formikProps.setFieldValue}
            withStrengthBar
          />
          <Submit
            submitting={submitting}
            label={intl.formatMessage({ id: 'Save new password' })}
          />
        </Form>
      )}
    </Formik>
  );
}

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

const enhance = compose(
  injectIntl,
);

export default enhance(ResetPasswordForm);
