import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PersonPinIcon from '@mui/icons-material/PersonPin';

function MapButton({ coords, intl }) {
  return (
    getButton(coords.lat, coords.lon, intl)
  );
}

const getButton = (lat, lon, intl) => (lat && lon ? (
  <Typography variant="hyperlink">
    <Link
      href={`https://maps.google.com?q=${lat},${lon}`}
      target="_blank"
      rel="noopener"
      underline="hover"
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'elegant.red',
      }}
    >
      <PersonPinIcon sx={{ mr: '3px' }} />
      {intl.formatMessage({ id: 'Show on map' })}
    </Link>
  </Typography>
) : <span />);

MapButton.propTypes = {
  coords: PropTypes.shape({
    lon: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.object.isRequired,
};

const enhance = compose(
  connect((state) => state.authReducer),
  injectIntl,
);

export default enhance(MapButton);
