import * as React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import authService from '../services/api/authService';
import { logout } from '../reducers/authActions';
import logoWhiteImage from '../assets/images/takeyourway-logo-white.png';

function Navigation({ intl, dispatch, setChangePasswordModalOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    authService
      .logout()
      .then(() => {
        dispatch(logout());
      })
      .catch(() => {
      });
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box
          component="div"
          sx={{
            mr: 2,
            ml: {
              xs: 3,
              sm: 0,
            },
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <Link to="/">
            <Box
              component="img"
              sx={{ maxHeight: '30px' }}
              src={logoWhiteImage}
              alt={intl.formatMessage({ id: 'Takeyourway logo' })}
            />
          </Link>
        </Box>
        <Box>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/profil">
              <FormattedMessage id="Profile" />
            </MenuItem>
            <MenuItem onClick={() => {
              handleClose();
              setChangePasswordModalOpen((prevState) => !prevState);
            }}
            >
              <FormattedMessage id="Change password" />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <FormattedMessage id="Logout" />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

Navigation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setChangePasswordModalOpen: PropTypes.func.isRequired,
};

const enhance = compose(
  connect((state) => state.authReducer),
  injectIntl,
);

export default enhance(Navigation);
